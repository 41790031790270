import { UmbracoFeaturedProducts } from '@shared/types';

import { IProductCardData } from 'components/ProductCard';
import { TABLET_BREAKPOINT } from 'utils/constants';

import {
  DefaultItemWidthDesktop,
  DefaultItemWidthMobile,
  MaxCarouselWidth,
  MaxItems,
} from './constants';

const getSlidesPerView = (windowWidth: number, maxSlides?: number): number => {
  if (windowWidth > MaxCarouselWidth) {
    return maxSlides || MaxItems;
  }

  if (windowWidth < TABLET_BREAKPOINT) {
    return Math.floor(windowWidth / DefaultItemWidthMobile) + 0.1;
  }

  return Math.floor(windowWidth / DefaultItemWidthDesktop);
};

const getOrderedItems = (
  productsOrder: UmbracoFeaturedProducts.IUmbracoSelectedProduct[],
  featuredProductsByLink: IProductCardData[]
): IProductCardData[] => {
  const orderedItems: IProductCardData[] = [];

  if (!productsOrder?.length) {
    return featuredProductsByLink;
  }

  productsOrder.forEach(({ id }: UmbracoFeaturedProducts.IUmbracoSelectedProduct) => {
    const item = featuredProductsByLink.find((product: IProductCardData) => product.pageId === id);

    if (item) {
      orderedItems.push(item);
    }
  });

  return orderedItems;
};

export default {
  getSlidesPerView,
  getOrderedItems,
};
