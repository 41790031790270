import React, { FC, useRef, useCallback, useState, useMemo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import classNames from 'classnames';

import RelatedCarouselNavigation from './RelatedCarouselNavigation';

import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';

import './RelatedCarousel.scss';
import { IPropsRelatedCarousel } from './model';

SwiperCore.use([Navigation, Pagination]);

const RelatedCarousel: FC<IPropsRelatedCarousel> = ({
  children,
  className,
  slidesPerView,
  ariaLabelPrev = 'prev',
  ariaLabelNext = 'next',
  isDesktop,
  dir = 'ltr',
  isShowPagination = true,
  isShowNavigation = true,
  loop = false,
  removeSpaceBetween = false,
}) => {
  const navPrevRef = useRef<HTMLButtonElement>(null);
  const navNextRef = useRef<HTMLButtonElement>(null);

  const [init, setInit] = useState(false);

  const isInitHadler = useCallback((): void => {
    setInit(!init);
  }, [init]);

  const spaceBetween = useMemo(
    () => (removeSpaceBetween ? 0 : isDesktop ? 20 : 10),
    [removeSpaceBetween, isDesktop]
  );

  return (
    <div
      className={classNames(
        `related-carousel__wrapper`,
        {
          'related-carousel--without-pagination': !isShowPagination,
        },
        className
      )}
    >
      <Swiper
        key={isShowPagination ? '0' : '1'}
        className="related-carousel"
        {...(isShowPagination && {
          pagination: {
            clickable: true,
          },
        })}
        slidesPerView={slidesPerView}
        spaceBetween={spaceBetween}
        wrapperTag="ul"
        navigation={{
          prevEl: navPrevRef.current,
          nextEl: navNextRef.current,
        }}
        dir={dir}
        centerInsufficientSlides
        loop={loop}
        onSwiper={isInitHadler}
      >
        {children?.length >= 1 &&
          children.map((el, index) => (
            <SwiperSlide
              key={`related-carousel-${index.toString()}`}
              className="related-carousel__slide"
              tag="li"
            >
              {el}
            </SwiperSlide>
          ))}
      </Swiper>
      {isShowNavigation ? (
        <RelatedCarouselNavigation
          ariaLabelPrev={ariaLabelPrev}
          ariaLabelNext={ariaLabelNext}
          navPrevRef={navPrevRef}
          navNextRef={navNextRef}
        />
      ) : null}
    </div>
  );
};

export default RelatedCarousel;
