import React, { FC, useMemo } from 'react';
import { Link } from 'gatsby';

import { sliceText } from 'utils/stringUtils/stringUtils';
import { getUmbracoUrl } from 'utils/transformers';
import useScreenRecognition from 'hooks/useScreenRecognition';

import ArticleImage from 'components/ArticleImage';
import ArticleTag from 'components/ArticleTag';
import IconCustom from 'common/IconCustom/IconCustom';

import { IPropsRelatedArticleCard } from './models';
import {
  articleIntroductionDesktopTextLength,
  articleIntroductionMobileTextLength,
} from './constants';
import './RelatedArticleCard.scss';
import './ShadowRelatedArticleCard.scss';
import { articleShortTitleTextLength } from './shadowConstants';

const RelatedArticleCard: FC<IPropsRelatedArticleCard> = ({
  articleShortTitle,
  articleIntroduction,
  link,
  mainArticleImage,
  mobileArticleImage,
  mainArticleImageAltText,
  tags,
  showMoreButtonAriaLabel,
}) => {
  const { isMobile } = useScreenRecognition();

  const articleText = useMemo(
    () =>
      sliceText(
        articleIntroduction,
        isMobile ? articleIntroductionMobileTextLength : articleIntroductionDesktopTextLength
      ),
    [articleIntroduction]
  );
  const articleTitle = useMemo(
    () => sliceText(articleShortTitle, articleShortTitleTextLength),
    [articleShortTitle]
  );

  return (
    <div className="article-card" data-test="RelatedArticleCard">
      <div className="article-card__link">
        {mainArticleImage ? (
          <ArticleImage
            dataTest="ArticleHeaderImage"
            image={{ ...mainArticleImage, mobileImage: mobileArticleImage?.gatsbyImage }}
            alt={mainArticleImageAltText}
            objectFit="cover"
            isLazyLoading
          />
        ) : null}
      </div>
      <Link
        to={link}
        className="article-card__main-link"
        aria-label={`${showMoreButtonAriaLabel} ${articleShortTitle}`}
      />
      {tags ? (
        <div data-test="ArticleTagsWrapper" className="article-card__tag-wrapper">
          {tags.map((tag) => (
            <ArticleTag key={tag.id} label={tag.name} url={getUmbracoUrl(tag.url)} />
          ))}
        </div>
      ) : null}
      <div className="article-card__link">
        <div className="article-card__title">{articleTitle}</div>
      </div>
      <div className="article-card__introduction-link">
        <div className="article-card__introduction">{articleText}</div>
      </div>
      {!isMobile ? (
        <div className="show-more__wrapper">
          <Link
            to={link}
            className="article-card__link show-more"
            aria-label={`${showMoreButtonAriaLabel} ${articleShortTitle}`}
          >
            <IconCustom icon="arrow-long" className="link__icon" />
          </Link>
        </div>
      ) : null}
    </div>
  );
};

export default RelatedArticleCard;
