import React, { FC } from 'react';
import { Link } from 'gatsby';

import './ArticleTag.scss';
import { ArticleTagProps } from './models';

const ArticleTag: FC<ArticleTagProps> = ({ label, url }) => (
  <div data-test="ArticleTag" className="article-tag">
    <Link className="article-tag__link" to={url}>
      {label}
    </Link>
  </div>
);

export default ArticleTag;
