import React, { FC, ReactElement } from 'react';
import { graphql, Link } from 'gatsby';
import classNames from 'classnames';

import GatsbyImage from 'common/GatsbyImage';
import Title from 'common/Title';

import './ProductCardRelated.scss';
import { IPropsProductCardRelated } from './models';

const ProductCardRelated: FC<IPropsProductCardRelated> = ({
  productCardData: { defaultProductImage, mobileProductImage, link, title, productType },
  activeColor,
}): ReactElement | null => {
  const aspectRatio = defaultProductImage?.gatsbyImage?.childImageSharp?.fluid.aspectRatio || 0;

  return (
    <article data-test="ProductCardRelated" className="product-card-related">
      <Link
        to={link}
        className="product-card-link"
        style={
          activeColor
            ? ({
                borderColor: activeColor,
              } as React.CSSProperties)
            : undefined
        }
      >
        <div
          className={classNames('image-wrapper', {
            horizontal: aspectRatio > 1,
            vertical: aspectRatio < 1,
          })}
        >
          <GatsbyImage
            image={{ ...defaultProductImage, mobileImage: mobileProductImage?.gatsbyImage }}
            alt={defaultProductImage.alt || title}
            objectFit="contain"
          />
        </div>
        <div className="info-block">
          <Title dataTestAttr="productCardTitle" className="info-block-title" Tag="h2">
            {title}
          </Title>
          <p className="info-block-type">{productType}</p>
        </div>
      </Link>
    </article>
  );
};

export const query = graphql`
  fragment FragmentProductCardRelated on Product {
    link
    pageId
    title
    productType
    sku
    defaultProductImage {
      ...FragmentGatsbyProps
      gatsbyImage {
        childImageSharp {
          fluid(maxHeight: 250) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      alt
    }
    mobileProductImage: defaultProductImage {
      alt
      fallbackUrl
      gatsbyImage {
        childImageSharp {
          fluid(maxHeight: 90, fit: CONTAIN, quality: 30) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;

export default ProductCardRelated;
