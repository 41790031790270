import { TABLET_BREAKPOINT } from 'utils/constants';
import {
  DefaultItemWidthDesktop,
  DefaultItemWidthMobile,
  MaxCarouselWidth,
  MaxItems,
} from './constants';

const getSlidesPerView = (windowWidth: number): number => {
  if (windowWidth > MaxCarouselWidth) {
    return MaxItems;
  }

  if (windowWidth < TABLET_BREAKPOINT) {
    return +(windowWidth / DefaultItemWidthMobile).toFixed(1);
  }

  return Math.floor(windowWidth / DefaultItemWidthDesktop);
};

export default {
  getSlidesPerView,
};
