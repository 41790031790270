import React, { FC, ReactElement, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import useScreenRecognition from 'hooks/useScreenRecognition';

import { Container } from 'layout';

import Button from 'common/Button';
import Title from 'common/Title';
import ProductCardRelated from 'components/ProductCardRelated';
import RelatedCarousel from 'components/RelatedCarousel';

import './FeaturedProducts.scss';
import './ShadowFeaturedProducts.scss';
import { IPropsFeaturedProducts } from './models';
import Helpers from './helpers';

const FeaturedProducts: FC<IPropsFeaturedProducts> = ({
  featuredProducts,
  featuredProductsByLink,
  featuredProductsByTag,
  productsOrder,
  ariaLabelPrev,
  ariaLabelNext,
  dir,
  activeColor,
  maxSlides,
  addClassName,
}): ReactElement | null => {
  const [slidesPerView, setSlidesPerView] = useState<number | null>(null);
  const { isDesktop, windowWidth } = useScreenRecognition();

  useEffect(() => {
    if (!windowWidth) {
      return;
    }

    setSlidesPerView(Helpers.getSlidesPerView(windowWidth, maxSlides));
  }, [windowWidth]);

  const { sectionTitle, ctaButton, selectedProductsMode } = featuredProducts[0].properties;
  const ctaButtonData = ctaButton?.[0]?.properties;

  const itemsToRender =
    selectedProductsMode?.[0] === 'by Tag'
      ? featuredProductsByTag
      : Helpers.getOrderedItems(productsOrder, featuredProductsByLink);

  return itemsToRender?.length ? (
    <div data-test="FeaturedProducts" className={classNames('featured-products', addClassName)}>
      <Container>
        <Title dataTestAttr="featuredProductsTitle" className="featured-products-title">
          {sectionTitle}
        </Title>
      </Container>

      {slidesPerView ? (
        <RelatedCarousel
          slidesPerView={slidesPerView}
          ariaLabelPrev={ariaLabelPrev}
          ariaLabelNext={ariaLabelNext}
          isDesktop={Boolean(isDesktop)}
          dir={dir}
          isShowPagination={itemsToRender.length > slidesPerView}
          isShowNavigation={itemsToRender.length > slidesPerView}
          loop={slidesPerView < itemsToRender.length}
        >
          {itemsToRender.map((product) => (
            <ProductCardRelated
              key={product.link}
              activeColor={activeColor}
              productCardData={product}
            />
          ))}
        </RelatedCarousel>
      ) : null}

      {ctaButtonData ? (
        <Container>
          <div className="cta-btn-wrapper">
            <Button
              link={ctaButtonData.link?.[0].url}
              ariaLabel={ctaButtonData.ariaLabel}
              className="cta-btn"
              textAlign="center"
            >
              {ctaButtonData.label}
            </Button>
          </div>
        </Container>
      ) : null}
    </div>
  ) : null;
};

export const query = graphql`
  fragment FragmentFeaturedProducts on TFeaturedProducts {
    properties {
      sectionTitle
      ctaButton {
        ...FragmentButton
      }
      selectedProductsMode
      selectedProductsIds {
        id
      }
    }
  }
`;

export default FeaturedProducts;
