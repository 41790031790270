import React, { FC } from 'react';
import classNames from 'classnames';
import Img from 'gatsby-image/withIEPolyfill';

import GatsbyImage from 'common/GatsbyImage';

import { IPropsArticleImage } from './models';
import './ArticleImage.scss';

const ArticleImage: FC<IPropsArticleImage> = ({
  image,
  responsiveImage,
  alt,
  dataTest = '',
  className = '',
  objectFit,
  isLazyLoading,
  autoHeight,
}) => (
  <div data-test="ArticleImage" className="article-image">
    {image ? (
      <GatsbyImage
        data-test={dataTest}
        className={classNames('article-image__wrapper', {
          [className]: className,
          'article-image__wrapper--auto-height': autoHeight,
        })}
        image={image}
        alt={alt}
        objectFit={objectFit || 'contain'}
        isLazyLoading={Boolean(isLazyLoading)}
        fadeIn={Boolean(isLazyLoading)}
        useFocalPoint
      />
    ) : null}
    {responsiveImage ? (
      <Img
        data-test={dataTest}
        fluid={responsiveImage}
        alt={alt}
        objectFit={objectFit || 'contain'}
        loading={isLazyLoading ? 'lazy' : 'eager'}
        fadeIn={!isLazyLoading}
        className={classNames('article-image__wrapper', { className })}
      />
    ) : null}
  </div>
);

export default ArticleImage;
