import React, { FC } from 'react';

import Icon from 'common/IconCustom';

import './RelatedCarouselNavigation.scss';
import './ShadowRelatedCarouselNavigation.scss';
import { IPropsRelatedCarouselNavigation } from './model';

const RelatedCarouselNavigation: FC<IPropsRelatedCarouselNavigation> = ({
  ariaLabelNext,
  ariaLabelPrev,
  navPrevRef,
  navNextRef,
}) => (
  <div className="related-carousel__navigation">
    <button
      type="button"
      className="navigation-item navigation-item-prev"
      aria-label={ariaLabelPrev}
      ref={navPrevRef}
    >
      <Icon icon="arrow" className="icon-prev" />
    </button>
    <button
      type="button"
      className="navigation-item navigation-item-next"
      aria-label={ariaLabelNext}
      ref={navNextRef}
    >
      <Icon icon="arrow" className="icon-next" />
    </button>
  </div>
);

export default RelatedCarouselNavigation;
